import {Ref, ref} from "vue";
import {SELECT_HOSPITAL_LIST} from "@/http/request";
import {Hospital} from "@/data/type";


class Data {
    HospitalList: Ref<Hospital[]>  = ref([]);
    __index: any = {};
    constructor() {
        SELECT_HOSPITAL_LIST((data: any) => {
            if (data) {
                this.HospitalList.value = data;
                data.map((res: any) => {
                    this.__index[res.id] = res.name;
                })
            }
        })
    }
}

const _d = new Data();
export function getHospitalName(hospitalId: number) {
    return _d.__index[hospitalId]?? "未知医院"
}
export const HospitalList = _d.HospitalList;