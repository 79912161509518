import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import axios from "axios";
import VueAxios from "vue-axios";


const app = createApp(App);
app.use(Antd);
app.use(VueAxios, axios)

app.use(router).mount('#app')
