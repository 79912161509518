const filter = [
  '血小板<50*10^9/L',
  '血小板>50*10^9/L',
  '较基线值下降20%以上',
  '较基线值下降20%以下',
];

export function getPlateletCountResult(index: number) {
  return filter[index] ?? '数据异常';
}
