<template>
  <a-menu
      mode="inline"
      theme="dark"
      :inline-collapsed="state.collapsed"
      v-model:openKeys="state.openKeys"
      v-model:selectedKeys="state.selectedKeys"
  >
    <a-sub-menu key="sub1">
      <template #title>
        <span>
          <MailOutlined/>
          <span>用户管理</span>
        </span>
      </template>
      <router-link to="/user">
        <a-menu-item key="1">用户列表</a-menu-item>
      </router-link>
    </a-sub-menu>
    <a-sub-menu key="sub2">
      <template #title>
        <span>
          <AppstoreOutlined/>
          <span>病例库</span>
        </span>
      </template>
      <router-link to="/case/tumor">
        <a-menu-item key="5">妇科肿瘤</a-menu-item>
      </router-link>
      <router-link to="/prenatal/tumor">
        <a-menu-item key="6">产前VTE</a-menu-item>
      </router-link>
      <router-link to="/postpartum/tumor">
        <a-menu-item key="7">产后VTE</a-menu-item>
      </router-link>
    </a-sub-menu>
    <a-sub-menu key="sub3">
      <template #title>
        <span>
          <AppstoreOutlined/>
          <span>系统设置</span>
        </span>
      </template>
      <router-link to="/admin">
        <a-menu-item key="8">管理员设置</a-menu-item>
      </router-link>
    </a-sub-menu>
  </a-menu>
</template>

<script lang="ts">
import {defineComponent, reactive} from "vue";
import {AppstoreOutlined, MailOutlined} from "@ant-design/icons-vue";

export default defineComponent({
  name: 'Menu',
  components: {
    MailOutlined,
    AppstoreOutlined,
  },
  setup() {
    const state = reactive({
      collapsed: false,
      selectedKeys: [],
      openKeys: [],
    });

    return {
      state,
    };
  }
})
</script>

