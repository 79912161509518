import {__axios} from './http';
import {notifyError} from '@/utils/notify';
import {LOGIN_PARAM, USER_LIST_SEARCH_PARAM} from '@/http/index';

/**
 * 搜索用户列表
 * @param param
 * @param callback
 * @constructor
 */
export function SELECT_USER_LIST(param: USER_LIST_SEARCH_PARAM) {
  return new Promise<any>((resolve, reject) => {
    __axios
      .post('/api/web/manager/selectUserInfoPageData', param)
      .then((res: any) => {
        if (res.code !== 0) {
          notifyError(res.message);
          reject();
        }
        resolve(res.result);
      })
      .catch(() => {
        reject();
      });
  });
}

export interface CaseSearchParam {
  caseId: string;
  hospitalId: number;
}

/**
 * 获取肿瘤病例治疗阶段信息
 * @param param
 * @param callback
 * @constructor
 */
export function SELECT_TUMOR_CASE_STAGE(param: CaseSearchParam) {
  return new Promise<any>((resolve, reject) => {
    __axios
      .post('/api/web/case/selectTumorCaseStageInfoList', param)
      .then((res: any) => {
        if (res.code !== 0) {
          notifyError(res.message);
          reject();
        }
        resolve(res.result);
      })
      .catch(() => {
        reject();
      });
  });
}
/**
 * 获取产科产前病例治疗阶段信息
 * @param param
 * @param callback
 * @constructor
 */
export function SELECT_PRENATAL_CASE_STAGE(param: CaseSearchParam) {
  return new Promise<any>((resolve, reject) => {
    __axios
      .post('/api/web/case/selectPrenatalCaseStageInfoList', param)
      .then((res: any) => {
        if (res.code !== 0) {
          notifyError(res.message);
          reject();
        }
        resolve(res.result);
      })
      .catch(() => {
        reject();
      });
  });
}
/**
 * 获取产科产后病治疗阶段信息
 * @param param
 * @param callback
 * @constructor
 */
export function SELECT_POSTPARTUM_CASE_STAGE(param: CaseSearchParam) {
  return new Promise<any>((resolve, reject) => {
    __axios
      .post('/api/web/case/selectPostpartumCaseStageInfoList', param)
      .then((res: any) => {
        if (res.code !== 0) {
          notifyError(res.message);
          reject();
        }
        resolve(res.result);
      })
      .catch(() => {
        reject();
      });
  });
}

/**
 * 获取肿瘤病例诊疗方案信息
 * @param param
 * @param callback
 * @constructor
 */
export function SELECT_TUMOR_CASE_TREAT(param: CaseSearchParam) {
  return new Promise<any>((resolve, reject) => {
    __axios
      .post('/api/web/case/selectTumorCaseTreatInfo', param)
      .then((res: any) => {
        if (res.code !== 0) {
          notifyError(res.message);
          reject();
        }
        resolve(res.result);
      })
      .catch(() => {
        reject();
      });
  });
}
/**
 * 获取产科产前病例诊疗方案信息
 * @param param
 * @param callback
 * @constructor
 */
export function SELECT_PRENATAL_CASE_TREAT(param: CaseSearchParam) {
  return new Promise<any>((resolve, reject) => {
    __axios
      .post('/api/web/case/selectPrenatalCaseTreatInfo', param)
      .then((res: any) => {
        if (res.code !== 0) {
          notifyError(res.message);
          reject();
        }
        resolve(res.result);
      })
      .catch(() => {
        reject();
      });
  });
}
/**
 * 获取产科产后病例诊疗方案信息
 * @param param
 * @param callback
 * @constructor
 */
export function SELECT_POSTPARTUM_CASE_TREAT(param: CaseSearchParam) {
  return new Promise<any>((resolve, reject) => {
    __axios
      .post('/api/web/case/selectPostpartumCaseTreatInfo', param)
      .then((res: any) => {
        if (res.code !== 0) {
          notifyError(res.message);
          reject();
        }
        resolve(res.result);
      })
      .catch(() => {
        reject();
      });
  });
}


export interface CASE_TUMOR_SEARCH_PARAM {
    admissionMonth: string | undefined;
    caseId: string | undefined;
    hospitalId: number | undefined;
    isFinish: number | undefined;
    isForHelp: number | undefined;
    isSpecial: number | undefined;
    pageNum: number;
    pageSize: number;
    patientName: string | undefined;
    riskLevel: number | undefined;
    stage: number | undefined;
    userId: string | undefined;
}
/**
 * 搜索肿瘤病例列表
 * @param param
 * @param callback
 * @constructor
 */
export function SELECT_CASE_TUMOR_LIST(param: CASE_TUMOR_SEARCH_PARAM) {
  return new Promise<any>((resolve, reject) => {
    __axios
      .post('/api/web/case/selectTumorCaseInfoPageData', param)
      .then((res: any) => {
        if (res.code !== 0) {
          notifyError(res.message);
          reject();
        }
        resolve(res.result);
      })
      .catch(() => {
        reject();
      });
  });
}

/**
 * 搜索肿瘤病例列表
 * @param param
 * @param callback
 * @constructor
 */
export function SELECT_PRENATAL_TUMOR_LIST(param: CASE_TUMOR_SEARCH_PARAM) {
  return new Promise<any>((resolve, reject) => {
    __axios
      .post('/api/web/case/selectPrenatalCaseInfoPageData', param)
      .then((res: any) => {
        if (res.code !== 0) {
          notifyError(res.message);
          reject();
        }
        resolve(res.result);
      })
      .catch(() => {
        reject();
      });
  });
}


/**
 * 搜索肿瘤病例列表
 * @param param
 * @param callback
 * @constructor
 */
export function SELECT_POSTPARTUM_TUMOR_LIST(param: CASE_TUMOR_SEARCH_PARAM) {
  return new Promise<any>((resolve, reject) => {
    __axios
      .post('/api/web/case/selectPostpartumInfoPageData', param)
      .then((res: any) => {
        if (res.code !== 0) {
          notifyError(res.message);
          reject();
        }
        resolve(res.result);
      })
      .catch(() => {
        reject();
      });
  });
}

// 1:科室 2:职称 3:既往抗凝用药史 4:疾病类型 5:并发症 6:药物预防-抗凝用药 7:预防措施
interface COMMON_Dir_PARAM {
    [key: string]: any;
}
/**
 * 搜索字典数据
 * @param param
 * @param callback
 * @constructor
 */
export function SELECT_COMMON_DIC(param: COMMON_Dir_PARAM) {
    return new Promise<any>((resolve, reject) => {
        __axios
            .get(`/api/wechat/mini/getCommonDic?${new URLSearchParams(param)}`)
            .then((res: any) => {
                if (res.code !== 0) {
                    notifyError(res.message);
                    reject();
                }
                resolve(res.result);
            })
            .catch(() => {
                reject();
            });
    });
}

/**
 * 搜索用户角色信息
 * @param param
 * @param callback
 * @constructor
 */
export function SELECT_USER_ROLE_LIST() {
    return new Promise<any>((resolve, reject) => {
        __axios
            .get('/api/web/manager/selectRoleInfoList')
            .then((res: any) => {
                if (res.code !== 0) {
                    notifyError(res.message);
                    reject();
                }
                resolve(res.result);
            })
            .catch(() => {
                reject();
            });
    });
}

/**
 * 查询用户列表
 * @param param
 * @param callback
 * @constructor
 */
export function LOGIN_URL(param: LOGIN_PARAM) {
    return new Promise((resolve, reject) => {
        __axios
            .post('/api/web/manager/login', param)
            .then((res: any) => {
                if (res.code !== 0) {
                    notifyError(res.message);
                    reject();
                    return;
                }
                resolve(res.result);
            })
            .catch(() => {
                reject();
            });
    });
}

export interface MANAGEMENT_LIST_PARAM {
    pageNum?: number;
    pageSize?: number;
    userName?: string;
}
/**
 * 查询用户列表
 * @param param
 * @param callback
 * @constructor
 */
export function SELECT_MANAGEMENT_LIST_URL(param: MANAGEMENT_LIST_PARAM) {
    return new Promise<any>((resolve, reject) => {
        __axios
            .post('/api/web/manager/selectManagerInfoPageData', param)
            .then((res: any) => {
                if (res.code !== 0) {
                    notifyError(res.message);
                    reject();
                    return;
                }
                resolve(res.result);
            })
            .catch(() => {
                reject();
            });
    });
}

/**
 * 查询用户列表
 * @param callback
 * @constructor
 */
export function SELECT_HOSPITAL_LIST(callback: Function) {
    __axios
        .get('/api/wechat/mini/getHospitalInfoList')
        .then((res: any) => {
            if (res.code !== 0) {
                notifyError(res.message);
                // reject();
                return;
            }
            callback(res.result);
        })
        .catch(() => {
            // reject();
        });
}

/**
 * 导出肿瘤病例文件
 * @param callback
 * @constructor
 */
export function EXPORT_FILE(downloadType: String, param: any) {
  return new Promise<any>((resolve, reject) => {
    __axios
      .post(`${downloadType}`, param, {
        responseType: 'blob',
      })
      .then((res: any) => {
        resolve(res.data);
      })
      .catch(() => {
        // reject();
      });
  });
}
