
import {defineComponent, reactive, Ref, ref, toRefs} from 'vue';
import {HospitalList} from '@/data/hospital';
import {getDepartmentList} from '@/data/department';
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import moment from 'moment';
import 'moment/dist/locale/zh-cn';
import {notifyError} from '@/utils/notify';

moment.locale('en');

const hospitalFilter = (input: string, option: any) => {
  return option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};
const filterChange = () => {
};
const isShowDrawer = ref(false);


type ComponentShow = {
  departments?: boolean,
  admissionMonth?: boolean,
  value?: any
}
type MaybeRef<T> = Ref<T> | T

export default defineComponent({
  name: 'TableFilter',
  props: {
    filter: {},
    callback: Function,
    filterShow: {},
    exportButtonCallback: Function,
  },
  setup(props: any) {
    const componentShow: MaybeRef<ComponentShow> = reactive({
      departments: true,
      admissionMonth: true
    });
    componentShow.value = Object.assign(componentShow, props.filterShow);

    const resetFilter = () => {
      props.filter.admissionMonth = undefined;
      props.filter.departmentsId = undefined;
    };

    const showDrawer = (flag: boolean) => {
      isShowDrawer.value = flag;
      if (!flag) {
        props.callback!();
      }
    };

    // 导出按钮点击事件
    const exportButtonFunction = () => {
      if (!props.exportButtonCallback) {
        notifyError("数据错误");
        return
      }
      // console.log(props.filter);
      props.exportButtonCallback(props.filter);

    }

    return {
      hospitalFilter,
      HospitalList,
      isShowDrawer,
      showDrawer,
      getDepartmentList,
      filterChange,
      ...toRefs(props),
      zhCN,
      componentShow,
      resetFilter,
      exportButtonFunction,
    };
  },
});
