<template>
    <div id="app">
      <router-view/>
    </div>
</template>


<style lang="less">
 #app {
   height: 100%;
   width: 100%;
 }

 .red {
   color: red !important;
 }
 .green {
   color: green !important;
 }
 .yellow {
   color: #cece67 !important;
 }
</style>
