
import {defineComponent, reactive, ref} from "vue";
import {LOGIN_URL} from "@/http/request";
import {LOGIN_PARAM} from "@/http";
import {putToken} from "@/data/token";
import router from "@/router";

export default defineComponent({
  name: '登陆',
  setup() {
    const formRef = ref();
    const loginParam = reactive<LOGIN_PARAM>({
      userName: '',
      password: ''
    });
    const loading = ref(false)
    const rules = {
      username: [
        {required: true, message: '请输入用户名', trigger: 'blur'},
      ],
      password: [
        {required: true, message: '请输入密码', trigger: 'blur'},
        {min: 6, message: '密码位数为6-15 位', trigger: 'blur'},
      ]
    }
    const login = () => {
      formRef.value
          .validate()
          .then(() => {
            loading.value = true;
            LOGIN_URL(loginParam)
                .then((result: any) => {
                  putToken(result.token);
                  router.push("/");
                })
                .finally(() => {
                  loading.value = false;
                })
          })
          .catch((a: any) => {
          })
    }

    return {
      formRef,
      loginParam,
      rules,
      login,
      loading
    }
  }
});
