
import {defineComponent, reactive} from "vue";
import {AppstoreOutlined, MailOutlined} from "@ant-design/icons-vue";

export default defineComponent({
  name: 'Menu',
  components: {
    MailOutlined,
    AppstoreOutlined,
  },
  setup() {
    const state = reactive({
      collapsed: false,
      selectedKeys: [],
      openKeys: [],
    });

    return {
      state,
    };
  }
})
