

import {defineComponent} from 'vue';
import {getIverEnzymeResult} from '@/data/iverEnzyme';
import {getPlateletCountResult} from '@/data/plateletCount';

export default defineComponent({
  name: 'StageBoard',
  props: {
    body: {}
  },
  setup(props) {
    console.log(props.body);

    return {
      getIverEnzymeResult,
      getPlateletCountResult
    };
  }
});
