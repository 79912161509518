
import {reactive, ref, defineComponent} from "vue";
import {HospitalList, getHospitalName} from "@/data/hospital";
import {getDepartmentList} from "@/data/department";
import {User} from "@/data/type";
import {SELECT_USER_LIST} from "@/http/request";
import {MaybeRef, USER_LIST_SEARCH_PARAM} from "@/http";
import TableFilter from "@/components/filter/TableFilter.vue";
import {findUserRole} from "@/data/UserRole";
const columns = [
  {
    title: "序号",
    dataIndex: "id",
    width: "80px",
  },
  {
    title: "姓名",
    dataIndex: "userName",
    width: "100px",
  },
  {
    title: "医院",
    dataIndex: "hospitalId",
    width: "20%",
    customRender: (a1: any) => getHospitalName(a1.text)
  },
  {
    title: "职位",
    dataIndex: "position",
    width: "100px"
  },
  {
    title: "角色类型",
    dataIndex: "roleId",
    customRender: (a1: any) => {
      const text = a1.text;
      return findUserRole(text)
    }
  },
];

const filterParam: MaybeRef<USER_LIST_SEARCH_PARAM> = reactive({
  pageNum: 1,
  pageSize: 5
});

const dataSource = ref<User[]>([]);
const searchLoading = ref(false);

const visible = ref(false);
const userInfo = reactive({
  info: {},
});

const hospitalFilter = (input: string, option: any) => {
  return option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

const filterChange = (value: string, option: string) => {
  console.log(filterParam)
  searchUserList()
};
const searchUserList = () => {
  searchLoading.value = true
  SELECT_USER_LIST(filterParam)
      .then(res => {
        const {
          records,
          total
        } = res;
        dataSource.value = records;
        tablePagination.total = total;
      })
  .finally(() => {
    searchLoading.value = false
  })
}

const isShowDrawer = ref(false);
const showDrawer = () => {
  isShowDrawer.value = true;
}
const closeDrawer = () => {
  isShowDrawer.value = false;
}

const onCustomRow = (e: any) => {
  return {
    onClick: () => {
      visible.value = true;
      userInfo.info = e;
    },
  };
};

// 表格分页配置
const tablePagination = reactive({
  current: 1,
  pageSize: 5,
  pageSizeOptions: ["5", "10", "20", "50", "100"],
  total: 0,
  change: (page: any, pageSize: any) => {
    filterParam.pageNum = page;
    searchUserList();
  },
  showSizeChange: (current: any, size: any) => {
    filterParam.pageSize = size;
    filterParam.pageNum = 1;
    tablePagination.pageSize = size;
    searchUserList();
  }
});

export default defineComponent({
  name: 'UserList',
  components: {
    TableFilter
  },
  setup() {
    searchUserList();
    return {
      columns,
      onCustomRow,
      visible,
      userInfo,
      tablePagination,
      HospitalList,
      getHospitalName,
      getDepartmentList,
      hospitalFilter,
      filterChange,
      filterParam,
      dataSource,
      showDrawer,
      isShowDrawer,
      closeDrawer,
      searchUserList,
      searchLoading
    };
  },
});
