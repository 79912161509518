<template>
  <a-config-provider :locale="zhCN">
    <!--    医院搜索框-->
    <label for="hospitalFilter">
      搜索医院：
      <a-select
          id="hospitalFilter"
          show-search
          allow-clear
          v-model:value="filter.hospitalId"
          placeholder="请选择筛选的医院"
          style="width: 200px"
          :filter-option="hospitalFilter"
          @change="callback"
          not-found-content="暂无医院"
      >
        <a-select-option v-for="d in HospitalList" :key="d.name" :value="d.id">
          {{ d.name }}
        </a-select-option>
      </a-select>
    </label>

    <!--  病例号搜索框-->
    <label for="caseIdFilter">
      搜索病例号：
      <a-input-search
          enter-button
          allow-clear
          style="width: 200px"
          id="caseIdFilter"
          maxlength="18"
          placeholder="请输入病例号"
          v-model:value="filter.caseId"
          @pressEnter="callback"
          @search="callback"
      >
      </a-input-search>
      <!--      <a-select-->
      <!--          id="caseIdFilter"-->
      <!--          show-search-->
      <!--          allow-clear-->
      <!--          v-model:value="filter.caseId"-->
      <!--          placeholder="请选择筛选的医院"-->
      <!--          style="width: 200px"-->
      <!--          :filter-option="hospitalFilter"-->
      <!--          @change="callback"-->
      <!--          not-found-content="暂无医院"-->
      <!--      >-->
      <!--        <a-select-option v-for="d in HospitalList" :key="d.name" :value="d.id">-->
      <!--          {{ d.name }}-->
      <!--        </a-select-option>-->
      <!--      </a-select>-->
    </label>
    <span class="button-group">
      <a-button type="primary" @click="showDrawer(true)">高级筛选</a-button>
      <a-button v-if="exportButtonCallback" type="primary" @click="exportButtonFunction">导出</a-button>
    </span>
    <a-drawer
        class="drawer"
        destroyOnClose
        :closable="false"
        :visible="isShowDrawer"
        @close="showDrawer(false)"
    >
      <template #title>
        <div class="drawer-title">
          <span>高级筛选</span>
          <a-button type="danger" size="small" @click="resetFilter">重置</a-button>
        </div>
      </template>
      <!-- 科室搜索框-->
      <div class="list">
        <div class="block" v-if="componentShow.departments">
          搜索科室：
          <a-select
              allow-clear
              v-model:value="filter.departmentsId"
              placeholder="请选择筛选的科室"
              style="width: 200px"
              @change="filterChange"
          >
            <a-select-option v-for="(data) in getDepartmentList()" :key="data.id" :value="data.id">
              {{ data.name }}
            </a-select-option>
          </a-select>
        </div>
        <!-- 入组时间-->
        <div class="block">
          入组时间：
          <a-month-picker
              allowClear
              format="YYYY-MM"
              valueFormat="YYYY-MM"
              v-model:value="filter.admissionMonth"/>
        </div>
      </div>
    </a-drawer>
  </a-config-provider>
</template>

<script lang="ts">
import {defineComponent, reactive, Ref, ref, toRefs} from 'vue';
import {HospitalList} from '@/data/hospital';
import {getDepartmentList} from '@/data/department';
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import moment from 'moment';
import 'moment/dist/locale/zh-cn';
import {notifyError} from '@/utils/notify';

moment.locale('en');

const hospitalFilter = (input: string, option: any) => {
  return option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};
const filterChange = () => {
};
const isShowDrawer = ref(false);


type ComponentShow = {
  departments?: boolean,
  admissionMonth?: boolean,
  value?: any
}
type MaybeRef<T> = Ref<T> | T

export default defineComponent({
  name: 'TableFilter',
  props: {
    filter: {},
    callback: Function,
    filterShow: {},
    exportButtonCallback: Function,
  },
  setup(props: any) {
    const componentShow: MaybeRef<ComponentShow> = reactive({
      departments: true,
      admissionMonth: true
    });
    componentShow.value = Object.assign(componentShow, props.filterShow);

    const resetFilter = () => {
      props.filter.admissionMonth = undefined;
      props.filter.departmentsId = undefined;
    };

    const showDrawer = (flag: boolean) => {
      isShowDrawer.value = flag;
      if (!flag) {
        props.callback!();
      }
    };

    // 导出按钮点击事件
    const exportButtonFunction = () => {
      if (!props.exportButtonCallback) {
        notifyError("数据错误");
        return
      }
      // console.log(props.filter);
      props.exportButtonCallback(props.filter);

    }

    return {
      hospitalFilter,
      HospitalList,
      isShowDrawer,
      showDrawer,
      getDepartmentList,
      filterChange,
      ...toRefs(props),
      zhCN,
      componentShow,
      resetFilter,
      exportButtonFunction,
    };
  },
});
</script>

<style lang="less" scoped>
.drawer .list .block {
  margin-bottom: 8px;
}

.drawer-title {
  display: flex;
  justify-content: space-between;
}

.button-group {
  button {
    margin-right: 8px;
  }
}
</style>
