import { notification } from "ant-design-vue";
import { h } from "vue";
import { CloseCircleFilled } from "@ant-design/icons-vue";

export const notifyError = (description: string) => {
  notification.open({
    message: "请求失败",
    description,
    icon: h(CloseCircleFilled, { style: "color: red" }),
  });
};
