import {SELECT_USER_ROLE_LIST} from "@/http/request";
import {ref} from "vue";


class UserRole {
     userRole = ref([]);
    __index:any = {}
    constructor() {
        SELECT_USER_ROLE_LIST()
            .then(res => {
                this.userRole.value = res;
                res.map((res: any) => {
                    this.__index[res.roleId] = res.roleName;
                })
                return this.userRole.value;
            })
    }
}
const data = new UserRole();


export function getUserRole() {
    return data.userRole.value;
}

export function findUserRole(id: number) {
    return data.__index[id]?? "未知角色"
}