import {ref} from "vue";

const __KEY = "!!Hospital..";

const CryptoJS = require("crypto-js");

export const Token = ref('');

export function putToken(token: string) {
    const encodeStr = encrypt(token, __KEY);

    Token.value = token;

    localStorage.setItem("_t", encodeStr);
}
export function getToken() {
    if (Token.value == '') {
        const encodeStr = localStorage.getItem("_t");
        if(!encodeStr) {
            return '';
        }

        const decodeStr = decrypt(encodeStr, __KEY);
        Token.value = decodeStr;

        return decodeStr;
    } else {
        return Token.value;
    }
}

function encrypt(content: string, key: string) {

    if (typeof content === 'object') {

        return CryptoJS.AES.encrypt(JSON.stringify(content), key).toString()
    }

    return CryptoJS.AES.encrypt(content.toString(), key).toString()
}

//解密
function decrypt(content: string, key: string) {

    try {

        return JSON.parse(CryptoJS.AES.decrypt(content, key).toString(CryptoJS.enc.Utf8))
    } catch (e) {

        return CryptoJS.AES.decrypt(content, key).toString(CryptoJS.enc.Utf8)
    }

}