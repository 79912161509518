import {SELECT_COMMON_DIC} from "@/http/request";
import {ref} from "vue";

export const DepartmentList = ref([]);


export function getDepartmentList() {
    if (DepartmentList.value.length > 0) return DepartmentList.value;

    SELECT_COMMON_DIC({type: 1})
        .then(res => {
            DepartmentList.value = res;
            return DepartmentList.value;
        })

}