<template>
  <div id="body">
    <div class="login">
      <a-form
          name="custom-validation"
          ref="formRef"
          :model="loginParam"
          :rules="rules"
          :label-col="{ span: 8 }"
          :wrapper-col="{ span: 14 }"
      >
        <a-form-item required has-feedback label="用户名" name="userName">
          <a-input v-model:value="loginParam.userName" placeholder="请输入用户名"/>
        </a-form-item>
        <a-form-item required has-feedback label="密码" name="password">
          <a-input v-model:value="loginParam.password" type="password" placeholder="请输入密码"/>
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 14, offset: 8 }">
          <a-button type="primary" html-type="submit" @click="login" :loading="loading">登陆</a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, reactive, ref} from "vue";
import {LOGIN_URL} from "@/http/request";
import {LOGIN_PARAM} from "@/http";
import {putToken} from "@/data/token";
import router from "@/router";

export default defineComponent({
  name: '登陆',
  setup() {
    const formRef = ref();
    const loginParam = reactive<LOGIN_PARAM>({
      userName: '',
      password: ''
    });
    const loading = ref(false)
    const rules = {
      username: [
        {required: true, message: '请输入用户名', trigger: 'blur'},
      ],
      password: [
        {required: true, message: '请输入密码', trigger: 'blur'},
        {min: 6, message: '密码位数为6-15 位', trigger: 'blur'},
      ]
    }
    const login = () => {
      formRef.value
          .validate()
          .then(() => {
            loading.value = true;
            LOGIN_URL(loginParam)
                .then((result: any) => {
                  putToken(result.token);
                  router.push("/");
                })
                .finally(() => {
                  loading.value = false;
                })
          })
          .catch((a: any) => {
          })
    }

    return {
      formRef,
      loginParam,
      rules,
      login,
      loading
    }
  }
});
</script>

<style lang="less" scoped>
#body {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  background: linear-gradient(181deg, #5b86ff 0%, #1AA1FF 40%, #58b9ff05 100%);

  .login {
    background-color: #fff;
    border: 1px solid;
    height: 300px;
    width: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
  }


}
</style>