
import {defineComponent, reactive, ref} from 'vue';
import {getHospitalName, HospitalList} from '@/data/hospital';
import {getDepartmentList} from '@/data/department';
import {User} from '@/data/type';
import {
  EXPORT_FILE,
  SELECT_CASE_TUMOR_LIST,
  SELECT_TUMOR_CASE_STAGE,
  SELECT_TUMOR_CASE_TREAT
} from '@/http/request';
import {MaybeRef} from '@/http';
import TableFilter from '@/components/filter/TableFilter.vue';
import {formatDate} from '@/utils/date';
import StageBoard from './StageBoard.vue';
import TreatBoard from './TreatBoard.vue';

const columns = [
  {
    title: '病例号',
    dataIndex: 'caseId',
    width: '80px',
  },
  {
    title: '患者姓名',
    dataIndex: 'patientName',
    width: '100px',
  },
  {
    title: '医院',
    dataIndex: 'hospitalId',
    width: '20%',
    customRender: (a1: any) => getHospitalName(a1.text)
  },
  {
    title: '疾病名称',
    dataIndex: 'illnessName',
    width: '120px',
  },
  {
    title: '当前阶段',
    dataIndex: 'stageName',
    width: '120px',
  },
  {
    title: '当前风险',
    dataIndex: 'riskName',
    width: '120px',
    customCell: (a1: any) => {
      return {
        class: a1.riskName == '高风险' ? 'red' : a1.riskName == '中风险' ? 'yellow' : 'green'
      };
    }
  },
  {
    title: '是否特殊病例',
    dataIndex: 'isSpecial',
    width: '100px',
    customRender: (a1: any) => a1.text ? '是' : '否',
    customCell: (a1: any) => {
      return {
        class: a1.isSpecial ? 'red' : ''
      };
    }
  },
  {
    title: '是否完成',
    dataIndex: 'isFinish',
    width: '100px',
    customRender: (a1: any) => a1.text ? '已完成' : '未完成',
    customCell: (a1: any) => {
      return {
        class: a1.isFinish ? 'green' : ''
      };
    }
  },
];

const filterParam: MaybeRef<any> = reactive({
  pageNum: 1,
  pageSize: 5
});

const dataSource = ref<User[]>([]);
const searchLoading = ref(false);
const tabActiveKey = ref('1');

const visible = ref(false);
const caseInfo = reactive({
  info: {},
  // 治疗阶段
  stage: [],
  // 诊疗方案
  treat: {},
});

const hospitalFilter = (input: string, option: any) => {
  return option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

const filterChange = (value: string, option: string) => {
  console.log(filterParam);
  searchUserList();
};
const searchUserList = () => {
  searchLoading.value = true;
  SELECT_CASE_TUMOR_LIST(filterParam)
      .then(res => {
        const {
          records,
          total
        } = res;
        dataSource.value = records;
        tablePagination.total = total;
      })
      .finally(() => {
        searchLoading.value = false;
      });
};

const isShowDrawer = ref(false);
const showDrawer = () => {
  isShowDrawer.value = true;
};
const closeDrawer = () => {
  isShowDrawer.value = false;
};

const onCustomRow = (e: any) => {
  return {
    onClick: () => {
      visible.value = true;
      caseInfo.info = e;
      const searchParam = {hospitalId: e.hospitalId, caseId: e.caseId};
      SELECT_TUMOR_CASE_STAGE(searchParam)
          .then(res => {
            caseInfo.stage = res;
          });
      SELECT_TUMOR_CASE_TREAT(searchParam)
          .then(res => {
            caseInfo.treat = res;
          });
    },
  };
};


// 表格分页配置
const tablePagination = reactive({
  current: 1,
  pageSize: 5,
  pageSizeOptions: ['5', '10', '20', '50', '100'],
  total: 0,
  change: (page: any, pageSize: any) => {
    filterParam.pageNum = page;
    searchUserList();
  },
  showSizeChange: (current: any, size: any) => {
    filterParam.pageSize = size;
    filterParam.pageNum = 1;
    tablePagination.pageSize = size;
    searchUserList();
  }
});

// 导出配置
const exportExcel = (param: any) => {
  EXPORT_FILE('/api/web/case/exportTumorCaseInfo', param)
  .then(res => {
    let url = window.URL.createObjectURL(res);
    // 生成一个a标签
    let link = document.createElement("a");
    link.style.display = "none";
    link.href = url;
    // 生成时间戳
    let timestamp=new Date().getTime();
    link.download = timestamp + ".xlsx";
    document.body.appendChild(link);
    link.click();
  })
}

export default defineComponent({
  name: 'UserList',
  components: {
    TableFilter,
    StageBoard,
    TreatBoard
  },
  setup() {
    searchUserList();
    return {
      columns,
      onCustomRow,
      visible,
      caseInfo,
      tablePagination,
      HospitalList,
      getHospitalName,
      getDepartmentList,
      hospitalFilter,
      filterChange,
      filterParam,
      dataSource,
      showDrawer,
      isShowDrawer,
      closeDrawer,
      searchUserList,
      searchLoading,
      formatDate,
      exportExcel,
    };
  },
});
