import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router';
import Home from '@/views/home/Home.vue';
import UserList from '@/views/user/list.vue';
import Login from '@/views/login/login.vue';
import Admin from '@/views/admin/list.vue';
import Case_Tumor from '@/views/case/tumor/list.vue';
import Prenatal_Tumor from '@/views/case/prenatal/list.vue';
import Postpartum_Tumor from '@/views/case/postpartum/list.vue';
import Test from '@/views/test.vue';

import {getToken} from '@/data/token';

const routes: RouteRecordRaw[] = [
    {
        path: '/',
        name: '首页',
        component: Home,
        children: [
            {
                path: 'user',
                name: '用户列表',
                component: UserList,
                meta: {
                    authLogin: true,
                },
            },
            {
                path: 'admin',
                name: '管理员列表',
                component: Admin,
                meta: {
                    authLogin: true,
                },
            },
            {
                path: '/case/tumor',
                name: '肿瘤数据列表',
                component: Case_Tumor,
                meta: {
                    authLogin: true,
                },
            },
            {
                path: '/prenatal/tumor',
                name: '产前数据列表',
                component: Prenatal_Tumor,
                meta: {
                    authLogin: true,
                },
            },
            {
                path: '/postpartum/tumor',
                name: '产后数据列表',
                component: Postpartum_Tumor,
                meta: {
                    authLogin: true,
                },
            },
        ],
        beforeEnter: (to, from, next) => {
            if (!getToken()) {
                next('/login');
            } else {
                next();
            }
        },
        meta: {
            authLogin: true,
        },
    },
    {
        path: '/login',
        name: '登陆',
        component: Login,
    },
    {
        path: '/test',
        name: '测试',
        component: Test,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
