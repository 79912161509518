import axios from 'axios';
import {notifyError} from '@/utils/notify';
import router from '@/router/index.ts';
import {getToken} from '@/data/token';

export const __axios = axios.create({
    baseURL: '/api',
    timeout: 12000,
    headers: {
        source: 'web',
    },
});

__axios.interceptors.request.use((res) => {
    res.headers.token = getToken();
    return res;
});

__axios.interceptors.response.use(
     (response) => {
        const responseType = response.config.responseType;
        if (responseType === 'blob') {
            return response;
        } else {
            return response.data;
        }
    },
     (error) => {
        if (error.response.status === 401) {
            router.push('/login');
        }
        if (error.response.status === 500) {
            notifyError('服务器错误');
        }
        return Promise.reject(error);
    },
);
