<template>
  <a-layout class="body">
    <a-layout-sider breakpoint="lg" collapsed-width="0">
      <Menu />
    </a-layout-sider>
    <a-layout>
      <a-layout-header :style="{ background: '#fff', padding: 0 }">
        【管理员登陆信息显示】
      </a-layout-header>
      <a-layout-content :style="{ margin: '24px 16px 0' }">
        <div :style="{ background: '#fff', minHeight: '500px' }" class="layout-content">
          <router-view/>
        </div>
      </a-layout-content>
      <a-layout-footer style="text-align: center"> Test</a-layout-footer>
    </a-layout>
  </a-layout>
</template>

<script>
import Menu from "@/views/home/Menu.vue"
import {defineComponent} from "vue";


export default defineComponent({
  components: {
    Menu
  },

  setup() {
    return {};
  },
});
</script>

<style lang="less" scoped>
.body {
  height: 100%;
}

#components-layout-demo-responsive .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.site-layout-sub-header-background {
  background: #fff;

  .sss {
    height: 10px;
  }
}

.site-layout-background {
  background: #fff;
}

[data-theme="dark"] .site-layout-sub-header-background {
  background: #141414;
}
</style>
