const filter = [
  '那屈肝素钙（速碧林）',
  '磺达肝癸钠(安卓）',
  '依诺肝素钠（克赛）',
  '达肝素钠(法安明）',
];

export function getAnticoagulantDrugs(index: number) {
  return filter[index] ?? '数据异常';
}
