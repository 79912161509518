<template>
  <div class="search-group">
    <TableFilter
        :filter="filterParam"
        :callback="searchUserList"
        :filter-show="{departments : false}"
        :export-button-callback="exportExcel"/>
  </div>
  <a-table
      :columns="columns"
      :data-source="dataSource"
      :row-key="(record) => record.caseId"
      :pagination="false"
      :custom-row="onCustomRow"
      :loading="searchLoading"
  >
  </a-table>
  <a-pagination
      v-model:current="tablePagination.current"
      :page-size-options="tablePagination.pageSizeOptions"
      :total="tablePagination.total"
      show-size-changer
      :page-size="tablePagination.pageSize"
      @showSizeChange="tablePagination.showSizeChange"
      @change="tablePagination.change"
      class="pagination"
  />
  <a-modal
      v-model:visible="visible"
      title="详细信息"
      :destroyOnClose="true"
      :footer="null"
      class="user-info-model"
  >
    <a-tabs tab-position="left">
      <a-tab-pane key="1" tab="病例详情">
        <div style="margin-top: 12px">
          <div class="line">
            <div class="title">病例号：</div>
            <div class="value">{{ caseInfo.info.caseId }}</div>
          </div>
          <div class="line">
            <div class="title">患者姓名：</div>
            <div class="value">{{ caseInfo.info.patientName }}</div>
          </div>
          <div class="line">
            <div class="title">患者年龄：</div>
            <div class="value">{{ caseInfo.info.age }}</div>
          </div>
          <div class="line">
            <div class="title">疾病名称：</div>
            <div class="value">{{ caseInfo.info.illnessName }}</div>
          </div>
          <div class="line">
            <div class="title">所属医院：</div>
            <div class="value">{{ getHospitalName(caseInfo.info.hospitalId) }}</div>
          </div>
          <div class="line">
            <div class="title">风险等级：</div>
            <div class="value">{{ caseInfo.info.riskName }}</div>
          </div>
          <div class="line">
            <div class="title">当前阶段：</div>
            <div class="value">{{ caseInfo.info.stageName }}</div>
          </div>
          <div class="line">
            <div class="title">是否进行求助：</div>
            <div class="value">{{ caseInfo.info.isForHelp ? '是' : '否' }}</div>
          </div>
          <div class="line">
            <div class="title">是否特殊病例：</div>
            <div class="value">{{ caseInfo.info.isSpecial ? '是' : '否' }}</div>
          </div>
          <div class="line">
            <div class="title">是否已完成：</div>
            <div class="value">{{ caseInfo.info.isFinish ? '是' : '否' }}</div>
          </div>
          <div class="line">
            <div class="title">创建时间：</div>
            <div class="value">{{ formatDate(caseInfo.info.createTime) }}</div>
          </div>
          <div class="line">
            <div class="title">更新时间：</div>
            <div class="value">{{ formatDate(caseInfo.info.admissionTime) }}</div>
          </div>
        </div>
      </a-tab-pane>
      <a-tab-pane key="2" tab="治疗阶段">
        <a-tabs size="small">
          <a-tab-pane key="a1" :tab="caseInfo?.stage[0]?.stageName ?? '未知'">
            <StageBoard :body="caseInfo?.stage[0]"/>
          </a-tab-pane>
          <a-tab-pane key="a2" :tab="caseInfo?.stage[1]?.stageName ?? '未知'">
            <StageBoard :body="caseInfo?.stage[1]"/>
          </a-tab-pane>
          <a-tab-pane key="a3" :tab="caseInfo?.stage[2]?.stageName ?? '未知'">
            <StageBoard :body="caseInfo?.stage[2]"/>
          </a-tab-pane>
        </a-tabs>
      </a-tab-pane>
      <a-tab-pane key="3" tab="诊疗方案">
        <TreatBoard :body="caseInfo?.treat"/>
      </a-tab-pane>
    </a-tabs>
  </a-modal>
</template>

<script lang="ts">
import {defineComponent, reactive, ref} from 'vue';
import {getHospitalName, HospitalList} from '@/data/hospital';
import {getDepartmentList} from '@/data/department';
import {User} from '@/data/type';
import {
  EXPORT_FILE,
  SELECT_CASE_TUMOR_LIST,
  SELECT_TUMOR_CASE_STAGE,
  SELECT_TUMOR_CASE_TREAT
} from '@/http/request';
import {MaybeRef} from '@/http';
import TableFilter from '@/components/filter/TableFilter.vue';
import {formatDate} from '@/utils/date';
import StageBoard from './StageBoard.vue';
import TreatBoard from './TreatBoard.vue';

const columns = [
  {
    title: '病例号',
    dataIndex: 'caseId',
    width: '80px',
  },
  {
    title: '患者姓名',
    dataIndex: 'patientName',
    width: '100px',
  },
  {
    title: '医院',
    dataIndex: 'hospitalId',
    width: '20%',
    customRender: (a1: any) => getHospitalName(a1.text)
  },
  {
    title: '疾病名称',
    dataIndex: 'illnessName',
    width: '120px',
  },
  {
    title: '当前阶段',
    dataIndex: 'stageName',
    width: '120px',
  },
  {
    title: '当前风险',
    dataIndex: 'riskName',
    width: '120px',
    customCell: (a1: any) => {
      return {
        class: a1.riskName == '高风险' ? 'red' : a1.riskName == '中风险' ? 'yellow' : 'green'
      };
    }
  },
  {
    title: '是否特殊病例',
    dataIndex: 'isSpecial',
    width: '100px',
    customRender: (a1: any) => a1.text ? '是' : '否',
    customCell: (a1: any) => {
      return {
        class: a1.isSpecial ? 'red' : ''
      };
    }
  },
  {
    title: '是否完成',
    dataIndex: 'isFinish',
    width: '100px',
    customRender: (a1: any) => a1.text ? '已完成' : '未完成',
    customCell: (a1: any) => {
      return {
        class: a1.isFinish ? 'green' : ''
      };
    }
  },
];

const filterParam: MaybeRef<any> = reactive({
  pageNum: 1,
  pageSize: 5
});

const dataSource = ref<User[]>([]);
const searchLoading = ref(false);
const tabActiveKey = ref('1');

const visible = ref(false);
const caseInfo = reactive({
  info: {},
  // 治疗阶段
  stage: [],
  // 诊疗方案
  treat: {},
});

const hospitalFilter = (input: string, option: any) => {
  return option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

const filterChange = (value: string, option: string) => {
  console.log(filterParam);
  searchUserList();
};
const searchUserList = () => {
  searchLoading.value = true;
  SELECT_CASE_TUMOR_LIST(filterParam)
      .then(res => {
        const {
          records,
          total
        } = res;
        dataSource.value = records;
        tablePagination.total = total;
      })
      .finally(() => {
        searchLoading.value = false;
      });
};

const isShowDrawer = ref(false);
const showDrawer = () => {
  isShowDrawer.value = true;
};
const closeDrawer = () => {
  isShowDrawer.value = false;
};

const onCustomRow = (e: any) => {
  return {
    onClick: () => {
      visible.value = true;
      caseInfo.info = e;
      const searchParam = {hospitalId: e.hospitalId, caseId: e.caseId};
      SELECT_TUMOR_CASE_STAGE(searchParam)
          .then(res => {
            caseInfo.stage = res;
          });
      SELECT_TUMOR_CASE_TREAT(searchParam)
          .then(res => {
            caseInfo.treat = res;
          });
    },
  };
};


// 表格分页配置
const tablePagination = reactive({
  current: 1,
  pageSize: 5,
  pageSizeOptions: ['5', '10', '20', '50', '100'],
  total: 0,
  change: (page: any, pageSize: any) => {
    filterParam.pageNum = page;
    searchUserList();
  },
  showSizeChange: (current: any, size: any) => {
    filterParam.pageSize = size;
    filterParam.pageNum = 1;
    tablePagination.pageSize = size;
    searchUserList();
  }
});

// 导出配置
const exportExcel = (param: any) => {
  EXPORT_FILE('/api/web/case/exportTumorCaseInfo', param)
  .then(res => {
    let url = window.URL.createObjectURL(res);
    // 生成一个a标签
    let link = document.createElement("a");
    link.style.display = "none";
    link.href = url;
    // 生成时间戳
    let timestamp=new Date().getTime();
    link.download = timestamp + ".xlsx";
    document.body.appendChild(link);
    link.click();
  })
}

export default defineComponent({
  name: 'UserList',
  components: {
    TableFilter,
    StageBoard,
    TreatBoard
  },
  setup() {
    searchUserList();
    return {
      columns,
      onCustomRow,
      visible,
      caseInfo,
      tablePagination,
      HospitalList,
      getHospitalName,
      getDepartmentList,
      hospitalFilter,
      filterChange,
      filterParam,
      dataSource,
      showDrawer,
      isShowDrawer,
      closeDrawer,
      searchUserList,
      searchLoading,
      formatDate,
      exportExcel,
    };
  },
});
</script>

<style lang="less">
.search-group {
  padding: 12px;

  label {
    margin: 10px;
  }
}

.user-info-model {
  .line {
    display: flex;
    font-size: 14px;
    height: 28px;
    align-items: center;
    //justify-content: center;
    .title {
    }

    .value {
    }

  }
}

.pagination {
  text-align: right;
  padding: 14pt !important;
}

.ant-modal-body {
  padding-top: 0 !important;
  padding-left: 12px !important;
}

//.ant-tabs-tabpane {
//  //padding-left: 12px !important;
//  margin-top: 8px !important;
//}
//.ant-tabs-bar {
//  margin-bottom: 0 !important;
//}
</style>
