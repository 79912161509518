<template>
<!--  <div class="search-group">-->
<!--    <TableFilter :filter="filterParam" :callback="searchUserList" />-->
<!--  </div>-->
  <a-table
      :columns="columns"
      :data-source="dataSource"
      :row-key="(record) => record.id"
      :pagination="false"
      :loading="searchLoading"
      bordered
  >
    <template #operator="{ record }">
      <a @click="onCustomRow(record)" style="margin-right: 12px">查看</a>
      <a @click="onCustomRow(record)">删除</a>
    </template>
  </a-table>
  <a-pagination
      v-model:current="tablePagination.current"
      :page-size-options="tablePagination.pageSizeOptions"
      :total="tablePagination.total"
      show-size-changer
      :page-size="tablePagination.pageSize"
      @showSizeChange="tablePagination.showSizeChange"
      @change="tablePagination.change"
      class="pagination"
   />
  <a-modal
      v-model:visible="visible"
      title="用户详情"
      :destroyOnClose="true"
      :footer="null"
      class="user-info-model"
  >
    <div class="line">
      <div class="title">用户id：</div>
      <div class="value">{{ userInfo.info.id }}</div>
    </div>
    <div class="line">
      <div class="title">用户姓名：</div>
      <div class="value">{{ userInfo.info.userName }}</div>
    </div>
    <div class="line">
      <div class="title">所属医院：</div>
      <div class="value">{{ userInfo.info.hospital ?? "暂无" }}</div>
    </div>
    <div class="line">
      <div class="title">职位：</div>
      <div class="value">{{ userInfo.info.position }}</div>
    </div>
  </a-modal>
</template>

<script lang="ts">
import {reactive, ref, defineComponent} from "vue";
import {HospitalList, getHospitalName} from "@/data/hospital";
import {getDepartmentList} from "@/data/department";
import {User} from "@/data/type";
import {MANAGEMENT_LIST_PARAM, SELECT_MANAGEMENT_LIST_URL} from "@/http/request";
import {MaybeRef, USER_LIST_SEARCH_PARAM} from "@/http";
import TableFilter from "@/components/filter/TableFilter.vue";
const columns = [
  {
    title: "管理员id",
    dataIndex: "userId",
    width: "80px",
  },
  {
    title: "账号名称",
    dataIndex: "userName",
    width: "100px",
  },
  {
    title: "操作",
    dataIndex: "operator",
    width: "100px",
    slots: { customRender: 'operator' },
  },
];

const filterParam: MaybeRef<MANAGEMENT_LIST_PARAM> = reactive({
  pageNum: 1,
  pageSize: 5,
  userName: undefined
});

const dataSource = ref<User[]>([]);
const searchLoading = ref(false);

const visible = ref(false);
const userInfo = reactive({
  info: {},
});

const hospitalFilter = (input: string, option: any) => {
  return option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

const filterChange = (value: string, option: string) => {
  console.log(filterParam)
  searchUserList()
};
const searchUserList = () => {
  searchLoading.value = true
  SELECT_MANAGEMENT_LIST_URL(filterParam)
      .then(res => {
        console.log(res)
        const {
          records,
          total
        } = res;
        dataSource.value = records;
        tablePagination.total = total;
      })
      .finally(() => {
        searchLoading.value = false
      })
}

const isShowDrawer = ref(false);
const showDrawer = () => {
  isShowDrawer.value = true;
}
const closeDrawer = () => {
  isShowDrawer.value = false;
}

const onCustomRow = (e: any) => {
  visible.value = true;
  userInfo.info = e;
};

// 表格分页配置
const tablePagination = reactive({
  current: 1,
  pageSize: 5,
  pageSizeOptions: ["5", "10", "20", "50", "100"],
  total: 0,
  change: (page: any, pageSize: any) => {
    filterParam.pageNum = page;
    searchUserList();
  },
  showSizeChange: (current: any, size: any) => {
    filterParam.pageSize = size;
    tablePagination.pageSize = size;
    searchUserList();
  }
});

export default defineComponent({
  name: 'UserList',
  components: {
    TableFilter
  },
  setup() {
    searchUserList();
    return {
      columns,
      onCustomRow,
      visible,
      userInfo,
      tablePagination,
      HospitalList,
      getHospitalName,
      getDepartmentList,
      hospitalFilter,
      filterChange,
      filterParam,
      dataSource,
      showDrawer,
      isShowDrawer,
      closeDrawer,
      searchUserList,
      searchLoading
    };
  },
});
</script>

<style lang="less">
.search-group {
  padding: 12px;

  label {
    margin: 10px;
  }
}

.user-info-model {
  .line {
    display: flex;
    font-size: 14px;
    height: 28px;

    .title {
    }

    .value {
    }
  }
}

.pagination {
  text-align: right;
  padding: 14pt !important;
}
</style>
