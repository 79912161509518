<template>
  <template v-if="body">
    <div style="margin-top: 12px">
      <div class="line">
        <div class="title">抗凝用药：</div>
        <div class="value">{{ getAnticoagulantDrugs(body.anticoagulantDrugs) }}</div>
      </div>
      <div class="line">
        <div class="title">是否药物预防：</div>
        <div class="value">{{ body.isChemoprophylaxis === 0 ? '否' : '是' }}</div>
      </div>
      <!--    <div class="line">-->
      <!--      <div class="title">抗血小板预防：</div>-->
      <!--      <div class="value">{{ body.plateletPrecaution }}</div>-->
      <!--    </div>-->
      <div class="line">
        <div class="title">预防措施：</div>
        <div class="value">{{ getPrecautionResult(body.precaution) }}</div>
      </div>
      <div class="line">
        <div class="title">用药剂量：</div>
        <div class="value">{{ body.useDrugDosage / 10 }}ml</div>
      </div>
      <div class="line">
        <div class="title">用药时长：</div>
        <div class="value">{{ body.useDrugDuration }}</div>
      </div>
      <div class="line">
        <div class="title">用药频率：</div>
        <div class="value">{{ body.useDrugFrequency }}</div>
      </div>
      <div class="line">
        <div class="title">抗血小板预防用药剂量：</div>
        <div class="value">{{ body.usePlateletDosage }}</div>
      </div>
      <div class="line">
        <div class="title">备注：</div>
        <div class="value">{{ body.remark }}</div>
      </div>
    </div>
  </template>
  <template v-else>
    暂无数据
  </template>
</template>


<script lang="ts">

import {defineComponent} from 'vue';
import {getIverEnzymeResult} from '@/data/iverEnzyme';
import {getPlateletCountResult} from '@/data/plateletCount';
import {getAnticoagulantDrugs} from '@/data/anticoagulantDrugs';
import {getPrecautionResult} from '@/data/precaution';

export default defineComponent({
  name: 'TreatBoard',
  props: {
    body: {}
  },
  setup(props) {
    console.log(props.body);

    return {
      getIverEnzymeResult,
      getPlateletCountResult,
      getAnticoagulantDrugs,
      getPrecautionResult
    };
  }
});
</script>

<style lang="less" scoped>
.ant-tabs-tabpane {
  margin-top: 0;
}

</style>
