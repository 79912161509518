<template>
  <div class="line">
    <div class="title">D-二聚体数值：</div>
    <div class="value">{{ body?.dimerNumber }}μg/ml</div>
  </div>
  <div class="line">
    <div class="title">GFR值：</div>
    <div class="value">{{ body?.gfr }}ml/min/1.73m²</div>
  </div>
  <div class="line">
    <div class="title">是否活动出血：</div>
    <div class="value">{{ body?.isActiveBleeding === 0 ? '无' : '有' }}</div>
  </div>
  <div class="line">
    <div class="title">肝酶情况：</div>
    <div class="value">{{ getIverEnzymeResult(body?.liverEnzyme) }}</div>
  </div>
  <div class="line">
    <div class="title">肝酶判断结果：</div>
    <div class="value">{{ body?.liverEnzymeResult }}</div>
  </div>
  <div class="line">
    <div class="title">血小板计数：</div>
    <div class="value">{{ getPlateletCountResult(body?.plateletCount) }}</div>
  </div>
  <!--  <div class="line">-->
  <!--    <div class="title">风险因素分值：</div>-->
  <!--    <div class="value">{{body?.riskFactorScores}}</div>-->
  <!--  </div>-->
  <div class="line">
    <div class="title">风险值：</div>
    <div class="value">{{ body?.riskScore + '分' }}</div>
  </div>
  <div class="line">
    <div class="title">血肌酐：</div>
    <div class="value">{{ body?.serumCreatinine }}uml/L</div>
  </div>
  <div class="line">
    <div class="title">肾功能判断结果：</div>
    <div class="value">{{ body?.serumCreatinineResult }}</div>
  </div>
  <div class="line">
    <div class="title">预计手术日期：</div>
    <div class="value">{{ body?.operationTime }}</div>
  </div>
  <div class="line">
    <div class="title">备注：</div>
    <div class="value">{{ body?.remark }}</div>
  </div>
</template>


<script lang="ts">

import {defineComponent} from 'vue';
import {getIverEnzymeResult} from '@/data/iverEnzyme';
import {getPlateletCountResult} from '@/data/plateletCount';

export default defineComponent({
  name: 'StageBoard',
  props: {
    body: {}
  },
  setup(props) {
    console.log(props.body);

    return {
      getIverEnzymeResult,
      getPlateletCountResult
    };
  }
});
</script>

<style lang="less" scoped>
.ant-tabs-tabpane {
  margin-top: 0;
}

</style>
